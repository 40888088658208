<template>
  <div class="layouts-static-container">
    <slot />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useMainStore } from '~/store/main';

export default {
  head() {
    return {
      title: this.mainStore.meta_title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.mainStore.meta_description,
        },
      ],
    };
  },
  computed: {
    ...mapStores(useMainStore),
  },
};
</script>

<style lang="scss" scoped>
.layouts-static-container {
  @apply bg-white h-full min-h-screen w-screen font-theme-text;
  user-select: none;
}
</style>
